import * as authActions from "../../store/actions/authAction";
import * as configActions from "../../store/actions/configAction";
import changeInput from "../../utils/changeInput";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import { dismisAlert, generateAlert } from "./../../utils/alertUtils";
import { contentTranslator } from "./../../utils/translatorUtils";
import React from "react";
import { AlertList } from "react-bs-notifier";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class ManageReportTemplates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            detailLoading: true,
            alerts: [],
            isUpdating: false,
            reportTemplateName: null,
            downloadLoading: false,
            reportTemplateTypes: [
                {
                    text: "Person SelfAssessed Skills Profile Report",
                    value: "PersonSelfAssessedSkillsProfileReport",
                    templateName: "SkillsTx_Individual_Skills_Profile_Template.dotx",
                },
                {
                    text: "Person Endorsed Skills Profile Report",
                    value: "PersonEndorsedSkillsProfileReport",
                    templateName: "SkillsTx_Endorsed_Skills_Profile_Template.dotx",
                },
                {
                    text: "Person Action Plan Report",
                    value: "PersonActionPlanReport",
                    templateName: "SkillsTx_Individual_Action_Plan_Template.dotx",
                },
                {
                    text: "Requirement Skills Profile Report",
                    value: "RequirementSkillsProfileReport",
                    templateName: "SkillsTx_Requirement_Template.dotx",
                },
            ],
            form: {
                reportTemplateType: {
                    validation: {
                        required: true,
                    },
                    value: "PersonSelfAssessedSkillsProfileReport",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
            },
            isFormValid: false,
            fileName: null,
            fileData: null,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.templateRef = React.createRef();
    }

    componentDidMount() {
        const { form, reportTemplateTypes } = this.state;
        this.props.onAuth.authCheckState().then(() => {
            if (reportTemplateTypes && reportTemplateTypes.length > 0) {
                const reportTemplateType = reportTemplateTypes[0];
                this.getReportTemplateNameByType(reportTemplateType.value);
            }

            this.setState({ loading: this.props.loading });
        });
    }

    getReportTemplateNameByType = (reportTemplateType) => {
        const form = this.state.form;
        this.setState({ detailLoading: true });

        this.props.onConfig.getReportTemplateName(reportTemplateType).then(() => {
            if (!this.props.reportTemplateLoading) {
                form.reportTemplateType.value = reportTemplateType;
                form.reportTemplateType.valid = true;
                form.reportTemplateType.isValidFormat = true;

                console.log("this.props.reportTemplateName", this.props.reportTemplateName);

                this.setState({ form, reportTemplateName: this.props.reportTemplateName });
            }
            this.setState({ detailLoading: this.props.reportTemplateLoading });
        });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
            isFormDirty: true,
        });
    };

    handleChangeReportTemplate = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, "reportTemplateType", value);
        this.setState(
            {
                form: updateState.form,
                isFormValid: updateState.valid,
                fileName: null,
                fileData: null,
            },
            () => {
                this.getReportTemplateNameByType(value);
            }
        );
    };

    handleDownloadTemplate = () => {
        const { form, reportTemplateName, reportTemplateTypes } = this.state;
        this.setState({ downloadLoading: true });
        this.props.onConfig.downloadReportTemplate(form.reportTemplateType.value).then(() => {
            if (!this.props.loadingExport && !this.props.configError) {
                if (this.props.blobData) {
                    if (reportTemplateName) {
                        FileDownload(this.props.blobData, reportTemplateName);
                    } else {
                        if (reportTemplateTypes && reportTemplateTypes.find((it) => form && it.value == form.reportTemplateType.value)) {
                            const reportTemplateType = reportTemplateTypes.find((it) => form && it.value == form.reportTemplateType.value);
                            FileDownload(this.props.blobData, reportTemplateType.templateName);
                        }
                    }
                }
            }
            this.setState({ downloadLoading: this.props.loadingExport });
        });
    };

    handleSelectTemplate = () => {
        this.templateRef.current.click();
    };

    handleTemplateChange = (event) => {
        const templateFile = event.target.files[0];
        toBase64(templateFile).then((result) => {
            this.setState({ fileName: templateFile.name, fileData: templateFile });
        });
    };

    handleUploadTemplate = () => {
        this.setState({ isUpdating: true });
        const { form, fileName, fileData } = this.state;
        this.props.onConfig.updateReportTemplate(form.reportTemplateType.value, fileData).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.configError) {
                    this.generateAlert("success", "Report template upload success.");
                    this.setState({ reportTemplateName: fileName, fileName: null, fileData: null });
                } else {
                    this.generateAlert("danger", this.props.configError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleResetTemplate = () => {
        this.setState({ fileName: null, fileData: null });
    };

    handleDeleteTemplate = () => {
        const { form, reportTemplateTypes } = this.state;
        if (reportTemplateTypes && reportTemplateTypes.find((it) => form && it.value == form.reportTemplateType.value)) {
            const reportTemplateType = reportTemplateTypes.find((it) => form && it.value == form.reportTemplateType.value);
            if (window.confirm(`You are about to delete ${reportTemplateType.text} template, are you sure?`)) {
                this.setState({ isUpdating: true });
                this.props.onConfig.clearReportTemplate(form.reportTemplateType.value).then(() => {
                    if (!this.props.isUpdating) {
                        if (!this.props.configError) {
                            this.generateAlert("success", "Report template deleted.");
                            this.setState({ reportTemplateName: null, fileName: null, fileData: null });
                        } else {
                            this.generateAlert("danger", this.props.configError.errData.Message);
                        }
                    }

                    this.setState({ isUpdating: this.props.isUpdating });
                });
            } else {
                return false;
            }
        }
    };

    render() {
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Report Templates");

        const { reportTemplateTypes, form, fileName, reportTemplateName } = this.state;

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <ContentWrapperLg style={{ backgroundColor: "#FFFFFF", width: "100%", maxWidth: "100%" }} className="contentWrapperLgAdmin-Style">
                            <Form>
                                <input ref={this.templateRef} type="file" style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.wordprocessingml.template" onChange={this.handleTemplateChange} />
                                <Row>
                                    <Col md={12}>
                                        <FormGroup row>
                                            <Label sm={3}>Templates</Label>
                                            <Col sm={9}>
                                                <Input type="select" name="reportTemplateType" id="reportTemplateType" value={form.reportTemplateType.value} disabled={this.state.isUpdating || this.state.detailLoading} onChange={this.handleChangeReportTemplate}>
                                                    {reportTemplateTypes.map((reportTemplateType, key) => (
                                                        <option key={key} value={reportTemplateType.value}>
                                                            {reportTemplateType.text}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        {(fileName || reportTemplateName) && (
                                            <React.Fragment>
                                                <FormGroup row>
                                                    <Label sm={3}>{fileName ? "New Template" : "Template Name"}</Label>
                                                    <Label sm={9}>
                                                        {fileName ? fileName : reportTemplateName}
                                                        {fileName && (
                                                            <React.Fragment>
                                                                <br />
                                                                <span style={{ color: "#FF0000" }}>* Please make sure that you already consult the report template with SkillsTX team.</span>
                                                            </React.Fragment>
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                            </React.Fragment>
                                        )}
                                        <FormGroup row>
                                            <Col sm={3}></Col>
                                            <Col sm={9}>
                                                <Button
                                                    color="primary"
                                                    onClick={this.handleDownloadTemplate}
                                                    style={{
                                                        fontSize: "16px",
                                                    }}
                                                    disabled={this.state.isUpdating || this.state.detailLoading}
                                                >
                                                    Download Template
                                                </Button>
                                                {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) && (
                                                    <React.Fragment>
                                                        &nbsp;&nbsp;
                                                        <Button
                                                            color="primary"
                                                            onClick={this.handleSelectTemplate}
                                                            style={{
                                                                fontSize: "16px",
                                                            }}
                                                            disabled={this.state.isUpdating || this.state.detailLoading}
                                                        >
                                                            Select New Template
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                                {fileName && (
                                                    <React.Fragment>
                                                        <React.Fragment>
                                                            &nbsp;&nbsp;
                                                            <Button
                                                                color="success"
                                                                onClick={this.handleUploadTemplate}
                                                                style={{
                                                                    fontSize: "16px",
                                                                }}
                                                                disabled={this.state.isUpdating || this.state.detailLoading}
                                                            >
                                                                Upload
                                                            </Button>
                                                            &nbsp;&nbsp;
                                                            <Button
                                                                color="primary"
                                                                onClick={this.handleResetTemplate}
                                                                style={{
                                                                    fontSize: "16px",
                                                                }}
                                                                disabled={this.state.isUpdating || this.state.detailLoading}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            &nbsp;&nbsp;
                                                        </React.Fragment>
                                                    </React.Fragment>
                                                )}
                                                {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) && reportTemplateName && (
                                                    <React.Fragment>
                                                        &nbsp;&nbsp;
                                                        <Button
                                                            color="danger"
                                                            onClick={this.handleDeleteTemplate}
                                                            style={{
                                                                fontSize: "16px",
                                                            }}
                                                            disabled={this.state.isUpdating || this.state.detailLoading}
                                                        >
                                                            Delete custom template
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </ContentWrapperLg>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        pageAssignments: state.adminPerson.pageAssignments,
        containerConfig: state.config.containerConfig,

        loadingExport: state.config.loadingExport,
        blobData: state.config.blobData,
        configError: state.config.error,
        reportTemplateLoading: state.config.loading,
        reportTemplateName: state.config.reportTemplateName,
        isUpdating: state.config.isUpdating,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageReportTemplates);
