import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class ConfigAPI {
    static getConfigSamlEnabledStatus() {
        return axios
            .get(`/configs/samlenabled`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getConfigCurrAssessment() {
        return axios
            .get(`/configs/assessment`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getContainerConfig() {
        return axios
            .get(`/configs/containerconfig`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getAdminConfig() {
        return axios
            .get(`/configs/adminconfig`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateAdminConfig(config) {
        return axios
            .put(`/configs/adminconfig`, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateAdminPersonMiscellaneousConfig(config) {
        return axios
            .put(`/configs/adminconfig/personMiscellaneous`, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static downloadPowerBIModel() {
        return axios
            .get(`/configs/downloadPowerBiModel`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getContainerAppSetting() {
        return axios
            .get(`/configs/containerappsetting`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateContainerAppSetting(appSetting) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        body.append("ssoSamlEnable", appSetting.ssoSamlEnable);
        body.append("ssoSamlEntityId", appSetting.ssoSamlEntityId);
        body.append("restrictLoginSSOOnly", appSetting.restrictLoginSSOOnly);
        body.append("byPassLoginPage", appSetting.byPassLoginPage);
        body.append("ssoSamlIpConfigurationOption", appSetting.ssoSamlIpConfigurationOption ? appSetting.ssoSamlIpConfigurationOption : "AUTO");

        body.append("ssoSamlIpMetadataAddress", appSetting.ssoSamlIpMetadataAddress ? appSetting.ssoSamlIpMetadataAddress : "");
        body.append("ssoSamlIpEntityId", appSetting.ssoSamlIpEntityId ? appSetting.ssoSamlIpEntityId : "");
        body.append("ssoSamlIpSingleSignOnEndpoint", appSetting.ssoSamlIpSingleSignOnEndpoint ? appSetting.ssoSamlIpSingleSignOnEndpoint : "");
        body.append("ssoSamlIpCertificateKeyVaultName", appSetting.ssoSamlIpCertificateKeyVaultName ? appSetting.ssoSamlIpCertificateKeyVaultName : "");

        body.append("ssoSamlClaimEmailAddressType", appSetting.ssoSamlClaimEmailAddressType);

        if (appSetting && appSetting.ssoSamlIpCertificateFile) {
            body.append("ssoSamlIpCertificateFile", appSetting.ssoSamlIpCertificateFile);
        }

        return axios
            .post(`/configs/containerappsetting`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getActiveUserCountStatus() {
        return axios
            .get(`/configs/activeUserCountStatus`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static inactivationUsersLessLogin() {
        return axios
            .post(`/configs/inactivationUsersLessLogin`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportMigrationImpactAssessmentReports() {
        return axios
            .get(`/configs/exportMigrationImpactAssessmentReports`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static exportMigrationImpactAssessmentReportsToEmail() {
        return axios
            .post(`/configs/exportMigrationImpactAssessmentReportsToEmail`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static downloadMigrationImpactAssessmentReports(token) {
        return axios
            .get(`/configs/downloadMigrationImpactAssessmentReports/${token}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static downloadTemplateReport(reportTemplateType) {
        return axios
            .get(`/configs/downloadReportTemplate/${reportTemplateType}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getTemplateReportName(reportTemplateType) {
        return axios
            .get(`/configs/reportTemplateName/${reportTemplateType}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateTemplateReport(reportTemplateType, reportTemplateFile) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        body.append("reportTemplateFile", reportTemplateFile);

        return axios
            .post(`/configs/updateReportTemplate/${reportTemplateType}`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static clearTemplateReport(reportTemplateType) {
        return axios
            .post(`/configs/clearReportTemplate/${reportTemplateType}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default ConfigAPI;
