import * as authActions from "../../store/actions/authAction";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import NavAdmin from "../common/NavAdmin";
import NavSecondary from "../common/NavSecondary";
import { contentTranslator } from "./../../utils/translatorUtils";
import Admin403Page from "./Admin403Page";
import AdminBranding from "./AdminBranding";
import Analytics from "./Analytics";
import ManageEmailTemplates from "./ManageEmailTemplates";
import ManageReportTemplates from "./ManageReportTemplates";
import ManageContent from "./contents/ManageContent";
import ManageEndorsement from "./endorsements/ManageEndorsement";
import ManageExtraFramework from "./extraFramework/ManageExtraFramework";
import ManageJobAssignment from "./jobAssignment/ManageJobAssignment";
import ManageJob from "./jobs/ManageJob";
import ManageJobCreate from "./jobs/ManageJobCreate";
import ManageJobFamily from "./jobs/ManageJobFamily";
import ManageJobProfile from "./jobs/ManageJobProfile";
import ManageLor from "./lor/ManageLor";
import ManageRelationships from "./relationships/ManageRelationships";
import ManageSelfAssessed from "./selfAssessed/ManageSelfAssessed";
import ManageProfiles from "./selfProfiles/ManageProfiles";
import ManageActionLogs from "./settings/ManageActionLogs";
import ManageSettings from "./settings/ManageSettings";
import ManageSfiaCredentials from "./sfiaCredentials/ManageSfiaCredentials";
import ManageSkillAttributes from "./skillAttributes/ManageSkillAttributes";
import ManageSSOSetting from "./ssoSetting/ManageSSOSetting";
import ManageActionPromotions from "./trainings/ManageActionPromotions";
import ManageProviders from "./trainings/ManageProviders";
import ManageTrainingJobs from "./trainings/ManageTrainingJobs";
import ManageTrainings from "./trainings/ManageTrainings";
import ManageUser from "./users/ManageUser";
import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { Col, Container, Modal, ModalBody, Row, Table } from "reactstrap";
import { bindActionCreators } from "redux";

class AdminHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            showFloatingLegend: false,
        };

        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        localStorage.setItem("manageProfileByAdminPopup", "FALSE");

        Promise.resolve(this.props.onAuth.myPeopleClearManagedRelationship()).then(() => {});
    }

    updateAdminFloatingLegendStatus = (showFloatingLegend) => {
        this.setState({ showFloatingLegend });
    };

    renderRotateNotification = () => {
        return (
            <Modal isOpen={this.state.showRotate} centered={true} onClick={() => this.closeRotateModal()}>
                <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <img src={process.env.PUBLIC_URL + "/assets/img/rotate.gif"} style={{ height: "100px" }} />
                        <br />
                        <span style={{ fontSize: "16px" }}>PLEASE ROTATE YOUR DEVICE</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>(OR CLICK TO CONTINUE)</span>
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    logoUpdated = () => {
        this.props.logoUpdated();
    };

    render() {
        const pageAssignments = this.props.pageAssignments;

        return (
            <React.Fragment>
                {this.renderRotateNotification()}
                <Container className="contentMainMax">
                    <NavSecondary isAdmin={true} />
                    <Row>
                        <Col md="2">
                            <NavAdmin></NavAdmin>
                        </Col>
                        <Col md="10">
                            <Switch>
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Users" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/users" render={(props) => <ManageUser {...props} mode="ManageUser" />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Assign Participants" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && (
                                    <Route exact path="/admin/assignparticipant" render={(props) => <ManageUser {...props} mode="AssignSurvey" />} />
                                )}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Track Participant Progress" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && (
                                    <Route exact path="/admin/surveyprogress" render={(props) => <ManageUser {...props} mode="TrackProgress" />} />
                                )}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Analytics" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/analytics" render={(props) => <Analytics {...props} />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Approve SelfAssessed" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/selfassessed" render={() => <ManageSelfAssessed />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Relationships" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/userrelationships" render={() => <ManageRelationships />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Requirements" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && (
                                    <Route exact path="/admin/requirements/:id/profile" render={() => <ManageJobProfile updateAdminFloatingLegendStatus={this.updateAdminFloatingLegendStatus} />} />
                                )}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Requirements" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/requirements" render={() => <ManageJob />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Requirements" && it.operations.find((it) => it.operation == "Open" && it.allow == true) && it.operations.find((it) => it.operation == "Add" && it.allow == true)) && (
                                    <Route exact path="/admin/requirements/create" render={() => <ManageJobCreate />} />
                                )}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Requirement Families" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/requirementFamilies" render={() => <ManageJobFamily />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Endorsements and Reports" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/endorsements" render={() => <ManageEndorsement />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Endorsements and Reports" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && (
                                    <Route exact path="/admin/endorsements/download/:token" render={() => <ManageEndorsement />} />
                                )}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Create Endorsed from Approved" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/selfprofiles" render={() => <ManageProfiles />} />}
                                {this.props.containerConfig &&
                                    this.props.containerConfig.showSfiaCertificationAdmin == true &&
                                    pageAssignments &&
                                    pageAssignments.find((it) => it.pageName == "Manage SFIA Credentials and Reports" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/sfiacredentials" render={() => <ManageSfiaCredentials />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Assign Requirements" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/requirementassignments" render={() => <ManageJobAssignment />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Skills and Attributes" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/skills" render={() => <ManageSkillAttributes />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Select Training Providers" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/trainingprofiders" render={() => <ManageProviders />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Action List" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/trainings" render={() => <ManageTrainings />} />}
                                {this.props.containerConfig &&
                                    this.props.containerConfig.enablePromoteAction == true &&
                                    pageAssignments &&
                                    pageAssignments.find((it) => it.pageName == "Manage Action List" && it.operations.find((it) => it.operation == "Manage Action Promotions" && it.allow == true)) && <Route exact path="/admin/actionpromotions" render={() => <ManageActionPromotions />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Assign Action Requirements" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/trainingjobs" render={() => <ManageTrainingJobs />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Features Settings" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/settings/actionlogs" render={() => <ManageActionLogs />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Features Settings" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/settings" render={() => <ManageSettings />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Features Settings" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && (
                                    <Route exact path="/admin/settings/downloadmigrationimpact/:token" render={() => <ManageSettings />} />
                                )}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Email Templates" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/emails" render={() => <ManageEmailTemplates />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Report Templates" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/reporttemplates" render={() => <ManageReportTemplates />} />}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Upload Company Logo" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && (
                                    <Route exact path="/admin/branding" render={(props) => <AdminBranding {...props} logoUpdated={this.logoUpdated} />} />
                                )}
                                {pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Page Labels" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && <Route exact path="/admin/contents" render={() => <ManageContent language={this.props.language} />} />}
                                {this.props.containerConfig && this.props.containerConfig.showExtraFrameworkAdmin == true && pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Extra Frameworks" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && (
                                    <Route exact path="/admin/extraframeworks" render={(props) => <ManageExtraFramework />} />
                                )}
                                {this.props.containerConfig && this.props.containerConfig.eeaFrameworkContainer == false && pageAssignments && pageAssignments.find((it) => it.pageName == "Manage LoR" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && (
                                    <Route exact path="/admin/lors" render={(props) => <ManageLor />} />
                                )}
                                {this.props.containerConfig && this.props.containerConfig.eeaFrameworkContainer == true && pageAssignments && pageAssignments.find((it) => it.pageName == "Manage LoR Category" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && (
                                    <Route exact path="/admin/lors" render={(props) => <ManageLor />} />
                                )}
                                {this.props.containerConfig && this.props.containerConfig.allowManageSSOSetting == true && pageAssignments && pageAssignments.find((it) => it.pageName == "Manage SSO Setting" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) && (
                                    <Route exact path="/admin/ssosetting" render={(props) => <ManageSSOSetting />} />
                                )}
                                <Route component={Admin403Page} />
                            </Switch>
                        </Col>
                    </Row>
                </Container>
                <Container className="contentMainMax fixed-top" style={{ display: this.state.showFloatingLegend ? "block" : "none" }}>
                    <Row>
                        <Col md="2"></Col>
                        <Col md="10">
                            <div className="containerMax">
                                <ContentWrapperLg className="contentWrapperLgAdmin-Style-Sticky" style={{ width: "100%", maxWidth: "100%", fontSize: "11px" }}>
                                    <Container className="p-0" style={{ fontSize: "11px", background: "#FFFFFF" }}>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td className="pl-0 pb-1" style={{ border: "0px", fontFamily: "IBMPlexSans-Bold" }} colSpan="5">
                                                        Skills Profile Key
                                                    </td>
                                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                        Not Selected
                                                    </td>
                                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                        Required
                                                    </td>
                                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                        Desirable
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" style={{ background: "#F5F5F5", border: "0px" }}></td>
                                                    <td style={{ background: "#E6E6E6", border: "0px" }}></td>
                                                    <td style={{ background: "#AAACAC", border: "0px" }}></td>
                                                    <td className="col-legend-desirable border-0"></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Container>
                                </ContentWrapperLg>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        contentTranslations: state.content.contentTranslations,
        pageAssignments: state.adminPerson.pageAssignments,
        language: state.language.language,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
